import React from 'react';
import './insights-dilog.css'
import '../../../../../App.css'
import 'react-dropdown/style.css';
import { useState } from 'react';
import { useEffect } from 'react';

const BoostInsights = (props) => {


    const [analysisReportData, setAnalysisReportData] = useState(props.selectedAnalysisData);
    const [analysisName, setAnalysisName] = useState(props.selectedAnalysisData.label);

    useEffect(() => {
    }, [])

    return (
        <>
            <div className='container ccontainer-boxes'>
                <div className='row'>
                    <div className='col-6'>
                        <p className='onscore-text'>{analysisReportData.label}</p>
                    </div>
                    <div className='col-6'>
                        <p className={(analysisReportData.status ?? '').toLowerCase() === 'good' || (analysisReportData.status ?? '').toLowerCase() === 'excellent' ? 'status-box good-btn-box' : (analysisReportData.status ?? '').toLowerCase() === 'bad' ? 'status-box bad-btn-box' : (analysisReportData.status ?? '').toLowerCase() === 'fair' ? 'status-box fair-btn-box' : ''}>{analysisReportData.status}</p>
                    </div>
                </div>
                <div className='accountetns-box border-top border-bottom row my-2'>
                    <div className='col-6'>
                        <div className='p-2 border-end'>
                            <div>
                                {analysisName === 'Income to Expense' && <h3 className='payments-tetx'>  {'$ ' + analysisReportData.totalAmount}</h3>}
                                {analysisName === 'Income to Investments' && <h3 className='payments-tetx'>  {'$ ' + analysisReportData.totalAmount}</h3>}
                                {analysisName === 'Income to Obligations' && <h3 className='payments-tetx'>  {'$ ' + analysisReportData.totalAmount}</h3>}
                                {analysisName === 'Income to Insurance' && <h3 className='payments-tetx'>  {'$ ' + analysisReportData.totalAmount}</h3>}
                            </div>
                            <div>
                                {analysisName === 'Income to Expense' && <p className='total-acc-text'> Total Expense</p>}
                                {analysisName === 'Income to Investments' && <p className='total-acc-text'> Total investment</p>}
                                {analysisName === 'Income to Obligations' && <p className='total-acc-text'> Total Obligations</p>}
                                {analysisName === 'Income to Insurance' && <p className='total-acc-text'> Total Insurance</p>}
                            </div>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='p-2 '>
                            <div>
                                {analysisName === 'Income to Expense' && <h3 className='payments-tetx'>  {analysisReportData.ratio}</h3>}
                                {analysisName === 'Income to Obligations' && <h3 className='payments-tetx'>  {analysisReportData.ratio}</h3>}
                                {analysisName === 'Income to Investments' && <h3 className='payments-tetx'>  {analysisReportData.ratio}</h3>}
                                {analysisName === 'Income to Insurance' && <h3 className='payments-tetx'>  {analysisReportData.ratio}</h3>}
                            </div>
                            <div>
                                <p className='total-acc-text'> of total income</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={(analysisReportData.status ?? '').toLowerCase() === 'good' || (analysisReportData.status ?? '').toLowerCase() === 'excellent' ? 'row title-sore-box my-4  good-btn-box' : (analysisReportData.status ?? '').toLowerCase() === 'bad' ? 'row title-sore-box my-4  bad-btn-box' : (analysisReportData.status ?? '').toLowerCase() === 'fair' ? 'row title-sore-box my-4  fair-btn-box' : ''}>
                    <div className='col-12 row align-items-center'>
                        <div className='col-2'>
                            <div>
                                {((analysisReportData.status ?? '').toLowerCase() === 'good' || (analysisReportData.status ?? '').toLowerCase() === 'excellent') && <i className="bi bi-hand-thumbs-up thump-icon-box"></i>}
                                {(analysisReportData.status ?? '').toLowerCase() === 'bad' && <i className="bi bi-exclamation-triangle bad-icon-box"></i>}
                                {(analysisReportData.status ?? '').toLowerCase() === 'fair' && <i className="bi bi-exclamation-triangle fair-icon-box"></i>}
                            </div>
                        </div>
                        <div className='col-10'>
                            <div className='ms-2 ms-sm-0'>
                                <p className={(analysisReportData.status ?? '').toLowerCase() === 'good' || (analysisReportData.status ?? '').toLowerCase() === 'excellent' ? 'mb-0 tinfi-title-text good-btn-box' : (analysisReportData.status ?? '').toLowerCase() === 'bad' ? 'mb-0 tinfi-title-text bad-btn-box' : (analysisReportData.status ?? '').toLowerCase() === 'fair' ? 'mb-0 tinfi-title-text fair-btn-box' : ''}>
                                    {analysisReportData?.title}
                                </p>
                                <p className='mb-0 title-message-text'>
                                    {analysisReportData?.message}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row text-center my-2 gotit-buttton-box'>
                    <div className='col-12'>
                        <button className='btn gotit-buttons' onClick={() => {
                            props.closeDialog()
                        }}>OK, Got it.</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BoostInsights;