import React, { useEffect, useState } from 'react';
import './boost-score-credit.css';
import '../../../App.css';
import { boostScoreReport } from '../../../redux/actions/dashboard_action';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Boostscorebank from '../boost-score-bank/boost-score-bank';
import getUserData from '../../../Common/helper';
import Loader from '../../loader/loader';
import Footer from '../../landing-page/Footer/Footer';
import { authCode, emailVerification } from '../../../redux/actions/auth_action';
import ErrorMessage from '../../Dashboard/error-message/error-message';
import ScoreDetails from '../../Dashboard/Header-Page/components/score-details/score-range-dailog';
import { ReactDialogBox } from 'react-js-dialog-box';
import ReactApexChart from "react-apexcharts";
import BoostInsights from '../../Dashboard/Header-Page/components/insihght/boost-insights-dilog';

const Score = require("../../../images/assets/BoostScore.png");
const Expense = require("../../../images/assets/income_expense.png");
const Obligation = require("../../../images/assets/income_obligation.png");
const Investments = require("../../../images/assets/income_investments.png");
const Insurance = require("../../../images/assets/income_insurance.png");


const SpendingLogo = require("../../../images/assets/top_spending.png");
const MerchantLogo = require("../../../images/assets/top_merchant.png");
const CreditCardLogo = require("../../../images/assets/credit_card.png");
const InvestmentLogo = require("../../../images/assets/top_investment.png");
const ObligationLogo = require("../../../images/assets/top_obligation.png");


const Boostscorecredit = (props) => {

  const [boostScoreReportData, setBoostScoreReportData] = useState();
  const [score, setScore] = useState(0);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [userData, setUserData] = useState();
  const [loader, setLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const [userExists, setUserExists] = useState();
  const [isVerified, setIsVerified] = useState(false);
  const [scoreDitailsDialog, setScoreDitailsDialog] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [dialogHeight, setDialogHeight] = useState('auto');
  const [emailErrorMessage, setEmailErrorMessage] = useState();
  const [message, setMessage] = useState();
  const [analysisReportData, setAnalysisReportData] = useState();
  const [incomeVsExpenseData, setIncomeVsExpenseData] = useState();
  const [topSpendingCategoryData, setTopSpendingCategoryData] = useState();
  const [topMerchantData, setTopMerchantData] = useState();
  const [topCardUtilizationData, setTopCardUtilizationData] = useState();
  const [topMonthlyObligationData, setMonthlyObligationData] = useState();
  const [selectedAnalysisData, setSelectedAnalysisData] = useState();
  const [insightsDialog, setInsightsDialog] = useState(false);
  const [investmentData, setInvestmentData] = useState();
  
  useEffect(() => {
    if (!user?.access_token) {
      navigate('/');
    } else {
      const data = getUserData()
      setUserData(data)
      getBoostScoreReport();
    }
  }, [])

  useEffect(() => {
    if (window.location.search && window.location.search !== "" && window.location.search.includes("state")) {

      if (!isVerified) {
        setIsVerified(true)
        var data = window.location.search.split("&");
        var state = data[0].split("=")[1];
        var code = data[1].split("=")[1];
        var param = {
          "oauthCode": code
        }
        setLoader(true);
        authCode(param).then(resp => {
          setLoader(false);
          setEmailErrorMessage(undefined)
          if (resp.statusCode === "ERROR") {
            navigate('/boostscore', { state: { "error": resp.statusMessage } });
          } else {
            navigate('/boostscore', { state: { "success": resp.statusMessage } })
          }
        }).catch(error => {
          setLoader(false);
          if (error.statusCode === "ERROR") {
            navigate('/boostscore', { state: { "error": error.statusMessage } });
          }
        })
      }
    }
  }, []);

  const submit = async () => {
    setEmailErrorMessage('')
    let rex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setShowError(false)
    if (!email || email === '') {
      setShowError(true)
    } else if (!rex.test(email)) {
      setEmailErrorMessage('Please enter valid email address');
    } else {
      var param = {
        "email": email
      }
      setLoader(true);
      emailVerification(param).then(resp => {
        setLoader(false);
        if (resp.statusCode === "SUCCESS") {
          window.location = resp.url;
        }
      }).catch(error => {
        setEmailErrorMessage(error?.statusMessage)
        setLoader(false);
      })
    }
  }

  const { user } = useSelector(state => {
    return state.authReducer;
  });

  const openInsights = (data, boostData) => {
    setSelectedAnalysisData(data);
    setInsightsDialog(true)
    setDialogHeight('auto')
    window.dispatchEvent(new Event('resize'))
  }

  const openScoreDetails = (name) => {
    setScoreDitailsDialog(true)
    setDialogHeight('auto')
  }

  const closeinsght = () => {
    setInsightsDialog(false)
    setDialogHeight('auto')
    window.dispatchEvent(new Event('resize'))
  }

  const closeDialog = () => {
    setIsOpenDialog(false)
    setDialogHeight('auto')
  }

  const getBoostScoreReport = () => {
    boostScoreReport().then((resp) => {
      setBoostScoreReportData(resp);
      setAnalysisReportData(resp.analysis)
      setIncomeVsExpenseData(resp.analysis.incomeVsExpenseAnalysis);
      setTopSpendingCategoryData(resp.analysis.categoryAnalysis);
      setTopMerchantData(resp.analysis.merchantAnalysis);
      setTopCardUtilizationData(resp.analysis.creditCardUtilization);
      setMonthlyObligationData(resp.analysis.obligation);
      setInvestmentData(resp.analysis.investment);
      if (resp.score > 0) {
        setScore(resp.score)
      }
      window.dispatchEvent(new Event('resize'))
    }).catch((error) => {

    })
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      submit()
    }
  };

  const closeScoreDetails = () => {
    setScoreDitailsDialog(false)
    setDialogHeight('auto')
  }

  let income = [];
  let expense = [];
  let categorie = [];


  let categorieName = [];
  let totalAmount = [];
  let spendingPer = [];

  let categorieMerName = [];
  let totalMerAmount = [];

  let cardData = [];
  let cardCategoriesName = [];

  let monthYearData = [];
  let monthAmount = [];
  let totalTransaction = [];

  let invCategorieName = [];
  let invTotalAmount = [];
  let invSpendingPer = [];

  useEffect(() => {

    {
      incomeVsExpenseData && incomeVsExpenseData.monthWiseData && incomeVsExpenseData.monthWiseData.forEach((incomeData) => {
        income.push(incomeData.incomeAmount)
        expense.push(incomeData.expenseAmount)
        categorie.push(incomeData.monthYear)
      })
    }

    {
      topSpendingCategoryData && topSpendingCategoryData.categoryData && topSpendingCategoryData.categoryData.forEach((spendingData) => {
        categorieName.push(spendingData.categoryName)
        totalAmount.push(spendingData.totalAmount)
        spendingPer.push(parseInt(spendingData.spendingPercentage))
      })
    }

    {
      topMerchantData && topMerchantData.categoryData && topMerchantData.categoryData.forEach((merchantData) => {
        categorieMerName.push(merchantData.categoryName)
        totalMerAmount.push(merchantData.totalAmount)
      })
    }

    {
      topCardUtilizationData && topCardUtilizationData.monthWiseAmount && topCardUtilizationData.monthWiseAmount.forEach((cardUtilization) => {
        cardData.push(cardUtilization.amount)
        cardCategoriesName.push(cardUtilization.monthYear)
      })
    }

    {
      topMonthlyObligationData && topMonthlyObligationData.monthWiseAmount && topMonthlyObligationData.monthWiseAmount.forEach((monthObligation) => {
        monthAmount.push(monthObligation.amount)
        monthYearData.push(monthObligation.monthYear)
        totalTransaction.push(monthObligation.totalTransaction)
      })
    }

    {
      investmentData && investmentData.categoryData && investmentData.categoryData.forEach((investment) => {
        invCategorieName.push(investment.categoryName)
        invSpendingPer.push(investment.spendingPercentage)
        invTotalAmount.push(investment.totalAmount)
      })
    }
    window.dispatchEvent(new Event('resize'))
  })

  const gradientColors = [
    {
      from: '#FFD166', // Starting color of gradient
      to: '#06D6A0'    // Ending color of gradient
    },
    {
      from: '#EF476F',
      to: '#118AB2'
    }
  ];

  const barChart = {

    series: [{
      type: 'column',
      name: 'Income',
      data: income
    }, {
      type: 'column',
      name: 'Expense',
      data: expense
    }
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: false,
          columnWidth: '40%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: categorie,
      },
      fill: {
        opacity: 1,
        type: "gradient",
        gradient: {
          type: "vertical",
          shadeIntensity: 1,
          opacityFrom: 1,
          opacityTo: 1,
          colorStops: [
            {
              offset: 0,
              color: "#61DFFB",
              opacity: 1
            },
            {
              offset: 100,
              color: "#3549F3",
              opacity: 1
            },
          ]
        }
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val.toLocaleString()
          }
        }
      },
    }
  };

  const pieChart = {

    series: spendingPer,
    options: {
      chart: {
        width: '55%',
        type: 'pie',
      },
      labels: categorieName,
      theme: {
        monochrome: {
          enabled: true
        }
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -5,
          }
        }
      },
      fill: {
        colors: ['#334BF3', '#8593F8', '#ADB7FA', '#D6DBFD', '#dddff0']
      },
      tooltip: {
        enabled: true,
        intersect: true,
        shared: false,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const categoryName = w.globals.labels[seriesIndex]
          const categoryAmount = totalAmount[seriesIndex]
          return '<div class="arrow_box">' +
            '<span>' + categoryName + ' : ' + '$ ' + categoryAmount.toLocaleString() + '</span>' +
            '</div>'
        }
      },
      dataLabels: {
        formatter(val, opts) {
          const dt = opts.w.globals.series[opts.seriesIndex]
          return [dt + "%"]
        }
      },
      legend: {
        show: false
      }
    },
  };

  const investmentChart = {

    series: invSpendingPer,
    options: {
      chart: {
        width: '55%',
        type: 'pie',
      },
      labels: invCategorieName,
      theme: {
        monochrome: {
          enabled: true
        }
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -5,
          }
        }
      },
      fill: {
        colors: ['#334BF3', '#8593F8', '#ADB7FA', '#D6DBFD', '#dddff0']
      },
      tooltip: {
        enabled: true,
        intersect: true,
        shared: false,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const categoryName = w.globals.labels[seriesIndex]
          const categoryAmount = invTotalAmount[seriesIndex]
          return '<div class="arrow_box">' +
            '<span>' + categoryName + ' : ' + '$ ' + categoryAmount.toLocaleString() + '</span>' +
            '</div>'
        }
      },
      dataLabels: {
        formatter(val, opts) {
          const dt = opts.w.globals.series[opts.seriesIndex]
          return [dt + "%"]
        }
      },
      legend: {
        show: false
      }
    },
  };

  const horizontalChart = {
    series: [{
      data: totalMerAmount
    }],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        }
      },
      fill: {
        opacity: 1,
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 1,
          opacityTo: 1,
          colorStops: [
            {
              offset: 0,
              color: "#7D25F6",
              opacity: 1
            },
            {
              offset: 100,
              color: "#2553F6",
              opacity: 1
            },
          ]
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          columnWidth: '40%',
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: categorieMerName,
        labels: {
          show: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
        }
      },
    },
  };

  const lineChart = {

    series: [{
      name: "Transcation",
      data: cardData
    }],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      fill: {
        opacity: 1,
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 1,
          opacityTo: 1,
          colorStops: [
            {
              offset: 0,
              color: "#364CF3",
              opacity: 1
            },
            {
              offset: 100,
              color: "#F66F67",
              opacity: 1
            },
          ]
        }
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val.toLocaleString()
          }
        }
      },
      xaxis: {
        type: 'category',
        categories: cardCategoriesName
      },

    },
  };

  const barChart1 = {

    series: [{
      name: 'Income',
      data: monthAmount
    }
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: false,
          columnWidth: '30%',
          endingShape: 'rounded',
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          const dataIndex = totalTransaction[opts.dataPointIndex];
          return dataIndex;
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: monthYearData
      },
      fill: {
        opacity: 1,
        type: "gradient",
        gradient: {
          type: "vertical",
          shadeIntensity: 1,
          opacityFrom: 1,
          opacityTo: 1,
          colorStops: [
            {
              offset: 0,
              color: "#FECE92",
              opacity: 1
            },
            {
              offset: 100,
              color: "#F45D5F",
              opacity: 1
            },
          ]
        }
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val.toLocaleString()
          }
        }
      }
    },

  };

  return (
    <>
      {loader && <Loader />}
      <div>

        <div className='container-lg container-fluid mt-4 my-sm-3'>
          <div className='row align-items-start g-1 g-sm-2 my-2 mb-3 px-sm-2 px-2 px-md-0 '>
            <div className='col-5'>
              <div className='ps-sm-2'>
                <h1 className='username-text mb-2 mb-sm-3'>Hi {userData?.given_name}  !</h1>

              </div>
            </div>
            <div className='col-7'>
              <div className='d-sm-flex justify-content-between justify-content-md-end align-items-center'>
                <div className='text-end text-sm-end me-3 me-sm-2'>
                  <p className='mb-0 userscore-textes'>Your <span className='boost-text'>Boost</span> score</p>
                  <h1 className={score && score !== null && score > 0 ? 'credit-score' : 'credit-score0'} >
                    {(score && score !== null) ? (score && score !== null && score !== null && Number(score) >= 0) ? score : '0' : '---'}
                  </h1>
                </div>
                <div className='d-flex align-items-center justify-content-end justify-content-md-center'>
                  <div className='order-1 order-sm-0 mx-0 mx-sm-2'>
                    {(score >= 780 && score <= 900) &&
                      <div className='excellect-box score-box'>
                        <p className='mb-0 score-box-text'>Excellent</p>
                      </div>}
                    {(score >= 706 && score <= 779) &&
                      <div className='goods-box score-box'>
                        <p className='mb-0 score-box-text'>Good</p>
                      </div>}
                    {(score >= 631 && score <= 705) &&
                      <div className='average-box score-box'>
                        <p className='mb-0 score-box-text'>Average</p>
                      </div>}
                    {(score > 0 && score <= 630) &&
                      <div className='poor-box score-box'>
                        <p className='mb-0 score-box-text'>Poor</p>
                      </div>}
                    {(score == undefined || score == null || score == 0) &&
                      <div className='score-not-show-box score-box mx-2'>
                        <p className='mb-0 score-box-text'><i className="bi bi-emoji-frown score-not-show-text"></i></p>
                      </div>}
                  </div>
                  <div className='order-0 order-sm-1'><i className="bi bi-info-circle score-info-icon me-1"
                    onClick={() => { openScoreDetails() }}
                  ></i></div>
                </div>
              </div>
            </div>

            <div className='crdit-score-dailog'>
              {scoreDitailsDialog && (
                <ReactDialogBox
                  closeBox={closeScoreDetails}
                  headerBackgroundColor='white'
                  headerTextColor='black'
                  closeButtonColor='black'
                  bodyBackgroundColor='white'
                  bodyTextColor='black'
                  headerText="Credit Score Range"
                  bodyHeight={dialogHeight}
                  borderRadius='8px'
                  headerHeight='65px'
                  modalWidth='auto'
                >
                  <ScoreDetails closeScoreDetails={closeScoreDetails} setDialogHeight={setDialogHeight} />
                </ReactDialogBox>)}
            </div>
          </div>
        </div>

        <section>
          <ErrorMessage message={message} />
        </section>

        {analysisReportData && analysisReportData.ratio &&
          <section id='insights'>
            <div className='container-lg container-fluid my-3'>
              <div className='insights-main-box'>
                <div className='row g-2'>
                  <div className='col-12'>
                    <p className='insights-heding-text'>INSIGHTS</p>
                    <div className='dividers w-100'></div>
                  </div>
                </div>
                <div className='row g-2'>
                  <div className='col-12'>
                    <p className='ms-md-3 insights-sub-text'>Check out the factors affecting your BOOST score!</p>
                  </div>
                  <div className='col-12 px-xl-5'>
                    <div className="row row-cols-2 row-cols-md-5 row-cols-xl-5 g-3 gt-1">
                      {analysisReportData && analysisReportData.ratio && analysisReportData.ratio.map((boostData, inx) => {
                        return (
                          <>
                            <div className="col insights-main-box-height" >
                              <div className='insights-sub-main-box h-100' key={boostData.status}>
                                <div className="cards h-100">
                                  <div className="card-bod px-2 py-3">
                                    <div className='insights-imag-box mb-2'>
                                      {(boostData && boostData.label === 'Income to Expense') ? <img src={Expense} className='img-fluid' /> : ""}
                                      {(boostData && boostData.label === 'Income to Investments') ? <img src={Investments} className='img-fluid' /> : ""}
                                      {(boostData && boostData.label === 'Income to Obligations') ? <img src={Obligation} className='img-fluid' /> : ""}
                                      {(boostData && boostData.label === 'Income to Insurance') ? <img src={Insurance} className='img-fluid' /> : ""}
                                    </div>
                                    <h5 className="card-title insights-box-main-title">{(boostData && boostData !== null) ? (boostData?.label && boostData?.label != null) ? boostData.label : '' : ''}</h5>
                                  </div>
                                  <div className={(boostData?.status ?? '').toLowerCase() === 'good' || (boostData?.status ?? '').toLowerCase() === 'excellent' ? 'px-2 py-3 limit-box dialog-boxes' : (boostData?.status ?? '').toLowerCase() === 'poor' || (boostData?.status ?? '').toLowerCase() === 'bad' ? 'px-2 py-3 age-box dialog-boxes' : (boostData?.status ?? '').toLowerCase() === 'average' || (boostData?.status ?? '').toLowerCase() === 'fair' ? 'px-2 py-3 payment-box dialog-boxes' : 'px-2 py-3'} onClick={() => { openInsights(boostData, analysisReportData) }}>
                                    <p className="card-text insights-card-main-text">{(boostData && boostData !== null) ? (boostData.status && boostData.status !== null) ? boostData?.status : '' : ''}</p>
                                    <div className='d-flex justify-content-between align-items-center mt-1'>
                                      <h5 className={(boostData?.status ?? '').toLowerCase() === 'good' || (boostData?.status ?? '').toLowerCase() === 'excellent' ? 'card-title accounts-box-title-text' : (boostData?.status ?? '').toLowerCase() === 'poor' || (boostData?.status ?? '').toLowerCase() === 'bad' ? 'card-title age-box-text-icon' : (boostData?.status ?? '').toLowerCase() === 'average' || (boostData?.status ?? '').toLowerCase() === 'fair' ? 'card-title paymet-text-title' : 'card-title'} >{(boostData && boostData !== null) ? (boostData?.ratio && boostData?.ratio !== null) ? (boostData?.ratio) : '' : ''}</h5>
                                      <i className="bi bi-chevron-right right-ariw-icons"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div></>)
                      })}
                    </div>
                    <div className='insightdialog'>
                      {insightsDialog && (
                        <ReactDialogBox
                          modalWidth='auto'
                          closeBox={closeinsght}
                          headerBackgroundColor='white'
                          headerTextColor='black'
                          closeButtonColor='black'
                          bodyBackgroundColor='white'
                          bodyTextColor='black'
                          bodyHeight={dialogHeight}
                          borderRadius='8px'
                        >
                          <BoostInsights closeDialog={closeinsght}
                            setDialogHeight={setDialogHeight}
                            selectedAnalysisData={selectedAnalysisData}
                            setAnalysisReportData={analysisReportData}
                            setIncomeVsExpenseData={incomeVsExpenseData}
                            setTopSpendingCategoryData={topSpendingCategoryData}
                            setTopMerchantData={topMerchantData}
                            setTopCardUtilizationData={topCardUtilizationData}
                            setMonthlyObligationData={topMonthlyObligationData}
                            setInvestmentData={investmentData}
                          />
                        </ReactDialogBox>)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section >}

        {analysisReportData && analysisReportData.sectionData && <section id='summatyaccount'>
          <div className='container-lg container-fluid my-3'>
            <div className="accordion" id="accordionPanelsStayOpenExample">
              <div className="accordion-item">
                <h2 className="accordion-header accordion-header accordion-button align-items-start pb-0" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                  <div className='w-100'>
                    <div>
                      <div className='d-flex justify-content-between'>
                        <p className="acc-info-text" >Boost score analysis</p>
                        <span className='text-end me-2 view-text-all'>View All</span>
                      </div>
                    </div>
                    <section>
                      <div className='border-top'>
                        <div className='my-2'>
                          <div className='row align-items-center g-3'>

                            <div className='col-lg-2'>
                              <p className='account-summary-text'>Transaction Summary</p>
                              <div className='dividers w-100'></div>
                            </div>
                            <div className='col-lg-10 row g-2'>
                              {analysisReportData && analysisReportData.sectionData && analysisReportData.sectionData.amountData.map((amtData, inx) => {
                                return (
                                  <div className='col-6 col-md-3' >
                                    <div className='acsummary-box' key={amtData.amount}>
                                      {(amtData && amtData.title === "Total Income" ? <div className='balance-left-border-box'></div> : amtData.title === "Investments" ? <div className='payments-left-border-box'></div> : amtData.title === "Expenses" ? <div className='open-left-border-box'></div> : amtData.title === "Obligations" ? <div className='closed-left-border-box'></div> : "")}
                                      <div className='text-center mx-auto'>
                                        <p className='summary-sub-text'>{(amtData && amtData?.title !== null) ? (amtData?.title && amtData?.title != null) ? amtData?.title : '-' : '-'}</p>
                                        <h3 className='summary-numbers'>{(amtData && amtData?.amount !== null) ? (amtData?.amount && amtData?.amount != null) ? "$ " + amtData?.amount.toLocaleString() : '-' : '-'}</h3>
                                      </div>
                                    </div>
                                  </div>)
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </h2>

                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse">
                  <div className="accordion-body">
                    {incomeVsExpenseData && incomeVsExpenseData.data != null &&
                      <div className='row'>
                        <div className='col-12'>
                          <div className='account-details-box'>
                            <div className="accordion incomevsexpense" id="accordionFlushExample">
                              <p className="acc-info-text">Transaction Analysis   <i className="bi bi-info-circle boost-info-icon me-1"></i></p>
                              <div className='dividers w-100'></div>

                              <div class="average-monthly-row mb-3">
                                <div class="average-monthly-column income-clm">Average Monthly Income :  ${incomeVsExpenseData && incomeVsExpenseData?.data[1] != null ? incomeVsExpenseData.data[1].amount : ''}</div>
                                <div class="average-monthly-column expense-clm">Average Monthly Expense :  ${incomeVsExpenseData && incomeVsExpenseData?.data[2] != null ? incomeVsExpenseData.data[2].amount : ''}</div>
                                <div class="average-monthly-column">Average Monthly Balance :  ${incomeVsExpenseData && incomeVsExpenseData?.data[0] != null ? incomeVsExpenseData.data[0].amount : ''}</div>
                              </div>

                              <ReactApexChart options={barChart.options} series={barChart.series} type="bar" height={350} />
                              <p className="chart-bottom-text">MONTHLY INCOME TO EXPENSE CHART</p>
                            </div>
                          </div>
                        </div>
                      </div>}


                    <div className='row mt-5 spending-category'>
                      {topSpendingCategoryData && topSpendingCategoryData.data != null &&
                        <div className='col-6 pb-category'>
                          <div className='account-details-box'>
                            <div className="accordion pie-chart">
                              <p className="acc-info-text mb-0">TOP SPENDING CATEGORIES <i className="bi bi-info-circle boost-info-icon me-1"></i></p>

                              <p className="subtitle-heading-chart">{topSpendingCategoryData && topSpendingCategoryData?.data?.percentageText != null ? '(' + topSpendingCategoryData.data.percentageText + ')' : ''}</p>
                              <div className='dividers w-100'></div>

                              <div className="top-chart-logo">
                                <img src={SpendingLogo} className='img-fluid' />
                                <div className="top-chart-text">
                                  <p className="acc-info-text mb-0">{topSpendingCategoryData && topSpendingCategoryData?.data?.name != null ? topSpendingCategoryData.data.name + ":" + " $" + topSpendingCategoryData.data.amount : ''}</p>
                                  <p className="subtitle-heading-chart">{topSpendingCategoryData && topSpendingCategoryData?.data?.label != null ? topSpendingCategoryData.data.label : ''}</p>
                                </div>
                              </div>
                              <ReactApexChart className="resize-chart" options={pieChart.options} series={pieChart.series} type="pie" height={300} />
                            </div>
                          </div>
                        </div>}

                      {topMerchantData && topMerchantData.data != null &&
                        <div className='col-6'>
                          <div className='account-details-box'>
                            <div className="accordion ">
                              <p className="acc-info-text mb-0">TOP MERCHANTS SPENDING <i className="bi bi-info-circle boost-info-icon me-1"></i></p>

                              <p className="subtitle-heading-chart">{topMerchantData && topMerchantData?.data?.percentageText != null ? '(' + topMerchantData.data.percentageText + ')' : ''}</p>
                              <div className='dividers w-100'></div>
                              <div className="top-chart-logo">
                                <img src={MerchantLogo} className='img-fluid' />
                                <div className="top-chart-text">
                                  <p className="acc-info-text mb-0">{topMerchantData && topMerchantData?.data?.name != null ? topMerchantData.data.name + ":" + " $" + topMerchantData.data.amount : ''}</p>
                                  <p className="subtitle-heading-chart">{topMerchantData && topMerchantData?.data?.label != null ? topMerchantData.data.label : ''}</p>
                                </div>
                              </div>
                              <ReactApexChart options={horizontalChart.options} series={horizontalChart.series} type="bar" height={265} />
                            </div>
                          </div>
                        </div>}
                    </div>

                    <div className='row mt-5 spending-category'>
                      {/* <div className='col-sm-12'> */}
                      {topCardUtilizationData && topCardUtilizationData.data != null && <div className='col-6 pb-category'>
                        <div className='account-details-box'>
                          <div className="accordion ">
                            <p className="acc-info-text mb-0">Credit Card Utilization <i className="bi bi-info-circle boost-info-icon me-1"></i></p>

                            <p className="subtitle-heading-chart">{topCardUtilizationData && topCardUtilizationData?.data?.percentageText != null ? '(' + topCardUtilizationData.data.percentageText + ')' : ''}</p>
                            <div className='dividers w-100'></div>
                            <div className="top-chart-logo">
                              <img src={CreditCardLogo} className='img-fluid' />
                              <div className="top-chart-text">
                                <p className="acc-info-text mb-0">{topCardUtilizationData && topCardUtilizationData?.data?.name != null ? topCardUtilizationData.data.name + ":" + " $" + topCardUtilizationData.data.amount : ''}</p>
                                <p className="subtitle-heading-chart">{topCardUtilizationData && topCardUtilizationData?.data?.label != null ? topCardUtilizationData.data.label : ''}</p>
                              </div>
                            </div>
                            <ReactApexChart className="resize-chart" options={lineChart.options} series={lineChart.series} type="line" height={265} />
                          </div>
                        </div>
                      </div>}

                      {investmentData && investmentData.amountInformation != null && <div className='col-6'>
                        <div className='account-details-box'>
                          <div className="accordion pie-chart">
                            <p className="acc-info-text mb-0">Top Investments <i className="bi bi-info-circle boost-info-icon me-1"></i></p>

                            <p className="subtitle-heading-chart">{investmentData && investmentData.amountInformation != null && investmentData?.amountInformation?.percentageText != null ? '(' + investmentData.amountInformation.percentageText + ')' : ''}</p>
                            <div className='dividers w-100'></div>

                            <div className="top-chart-logo">
                              <img src={InvestmentLogo} className='img-fluid' />
                              <div className="top-chart-text">
                                <p className="acc-info-text mb-0">{investmentData && investmentData.amountInformation != null && investmentData?.amountInformation?.name != null ? investmentData.amountInformation.name + ":" + " $" + investmentData.amountInformation.amount : ''}</p>
                                <p className="subtitle-heading-chart">{investmentData && investmentData.amountInformation != null && investmentData?.amountInformation?.label != null ? investmentData.amountInformation.label : ''}</p>
                              </div>
                            </div>
                            <ReactApexChart options={investmentChart.options} series={investmentChart.series} type="pie" height={300} />
                          </div>
                        </div>
                      </div>}
                      {/* </div> */}
                    </div>

                    {topMonthlyObligationData && topMonthlyObligationData.data != null && <div className='row mt-5'>
                      <div className='col-12'>
                        <div className='account-details-box'>
                          <div className="accordion ">
                            <p className="acc-info-text mb-0">Monthly obligations <i className="bi bi-info-circle boost-info-icon me-1"></i></p>

                            <p className="subtitle-heading-chart">{topMonthlyObligationData && topMonthlyObligationData?.data?.percentageText != null ? '(' + topMonthlyObligationData.data.percentageText + ')' : ''}</p>
                            <div className='dividers w-100'></div>
                            <div className="top-chart-logo">
                              <img src={ObligationLogo} className='img-fluid' />
                              <div className="top-chart-text">
                                <p className="acc-info-text mb-0">{topMonthlyObligationData && topMonthlyObligationData?.data?.name != null ? topMonthlyObligationData.data.name + ":" + " $" + topMonthlyObligationData.data.amount : ''}</p>
                                <p className="subtitle-heading-chart">{topMonthlyObligationData && topMonthlyObligationData?.data?.label != null ? topMonthlyObligationData.data.label : ''}</p>
                              </div>
                            </div>
                            <ReactApexChart options={barChart1.options} series={barChart1.series} type="bar" height={350} />
                            <p className="chart-bottom-text">MONTHLY OBLIGATIONS CHART</p>
                          </div>
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>}

        <div className='container-lg container-fluid'>
          <div className='row my-2 g-2 align-items-center'>
            <div className='col-sm-12'>
              <div className='boxes'>
                <div className='row g-2 align-items-start'>
                  <div className='col-sm-12 col-md-4'>
                    <div className='row text-start align-items-center align-items-sm-start'>
                      <div className='col-2'>
                        <div className='mt-1 boost-score-image-box'>
                          <img src={Score} className='img-fluid' />
                        </div>
                      </div>
                      <div className='col-10'>
                        <h1 className='boost-score-text ms-3 ms-sm-2 ms-md-3 mb-0 mt-1 mt-md-0'><span className='boost-text'>Boost</span> your score</h1>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-12 text-start col-md-8'>
                    <div className='score-buttton-box box-score-credit-box'>
                      <input type='text' className='form-control input-box' placeholder='Enter Your Email Here' value={email}
                        onKeyDown={handleKeyDown} onChange={(e) => { setEmail(e.target.value) }}
                      />
                      <div className='mobile-view-error'>
                        {(showError && (!email || email === '')) && <span className="demo-tetx text-light">Please enter your email</span>}
                        {emailErrorMessage !== '' && <span className="demo-tetx mt-2 text-light">{emailErrorMessage}</span>}
                      </div>
                      <button className='score-btn credit-score-button'
                        onClick={() => { submit() }}
                      >BOOST Your Score</button>
                    </div>
                    <div className='dashboard-error-view'>
                      {(showError && (!email || email === '')) && <span className="demo-tetx text-light">Please enter your email</span>}
                      {emailErrorMessage !== '' && <span className="demo-tetx mt-2 text-light">{emailErrorMessage}</span>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Boostscorebank setScore={setScore}
          setMessage={setMessage}
          scrollToTop={props.scrollToTop}
          setAnalysisReportData={setAnalysisReportData}
          setIncomeVsExpenseData={setIncomeVsExpenseData}
          setTopSpendingCategoryData={setTopSpendingCategoryData}
          setTopMerchantData={setTopMerchantData}
          setTopCardUtilizationData={setTopCardUtilizationData}
          setMonthlyObligationData={setMonthlyObligationData}
          setInvestmentData={setInvestmentData}
        />

        <div>
          <Footer />
        </div>

      </div >

    </>
  )
}

export default Boostscorecredit