import AsyncStorage from '@react-native-async-storage/async-storage';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setAuthHeader } from '../../../config/api';
import { setB2BAuthHeader } from '../../../config/b2b';
import { setAuthHeaderProd } from '../../../config/prod-api';
import { dispatchAction } from '../../../redux/actions/common_action';
import { loginAction } from '../../../redux/actions/auth_action';
import { b2bLogin } from '../../../redux/actions/b2b_login';
import { getConfiguration } from '../../../redux/actions/dashboard_action'
import { resendEmail } from '../../../redux/actions/user_action'
import '../../../App.css';
import './login.css'
import Loader from '../../loader/loader';
import Footer from '../../landing-page/Footer/Footer'
import { SIGN_IN_ERROR, SIGN_IN_SUCCESS } from '../../../redux/constants';
import { getProfileData } from '../../../redux/actions/dashboard_action';
import { getCommonUATClientTokenB2B } from '../../Component/GenetateUATClientTokenB2B';
import { getClientConfiguration } from '../../../redux/actions/b2b_action';

const Logo = require("../../../images/assets/Logo.png");

function Login() {
    const [email, setEmail] = useState("")
    const [passwrd, setPassword] = useState("")
    const [loader, setLoader] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [emailVerify, setEmailVerify] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user } = useSelector(state => {
        return state.authReducer;
    });

    useEffect(() => {
        // if (user?.access_token) {
        //     navigate('/dashboard');
        // }
    }, [])

    const login = () => {
        setEmailVerify('')
        setErrorMessage('')
        setShowError(false)
        let rex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email === '' || passwrd === '') {
            setShowError(true)
        } else if (!rex.test(email)) {
            setEmailVerify('Please enter valid email address');
        } else {
            const param = {
                "email": email,
                "password": passwrd
            };
            setLoader(true)
            loginAction(param).then(resp => {
                if (resp.statusCode === "SUCCESS") {
                    AsyncStorage.setItem('token', resp.access_token)
                    setAuthHeader(resp.access_token);
                    setAuthHeaderProd(resp.access_token);
                    dispatch(dispatchAction(SIGN_IN_SUCCESS, resp, false));
                    getCommonUATClientTokenB2B(
                        () => {
                            getClientConfiguration().then((resp) => {
                                if (resp?.allConfiguration && resp.statusCode === "SUCCESS") {
                                    const jsonString = JSON.stringify(resp?.allConfiguration);
                                    // Store the JSON string in local storage
                                    localStorage.setItem('allConfiguration', jsonString);
                                }
                            }).catch((error) => {
                            }).finally(() => {

                            });
                        },
                        (errorMessage) => {
                        }
                    );
                    //GET Profile Information of client
                    getProfileData().then((resp) => {
                        if (resp.statusCode === "SUCCESS") {
                            localStorage.setItem('fullName', resp.fullName)
                            localStorage.setItem('email', resp.email)
                            localStorage.setItem('businessName', resp.businessName)
                        }
                    })
                        .catch((error) => {
                            console.log(error);
                        });


                    //GET Client Id and ClientScret Id
                    // getConfiguration().then(resp => {
                    //     if (resp) {
                    //         const params = {
                    //             clientId: resp.clientId,
                    //             clientSecret: resp.clientSecret
                    //         }
                    //         // B2B Login for get B2B Access Token
                    //         b2bLogin(params).then(resp => {
                    //             if (resp.access_token) {
                    //                 AsyncStorage.setItem('b2btoken', resp.access_token)
                    //                 setB2BAuthHeader(resp.access_token)
                    //             }

                    //         }).catch(error => {
                    //             if (error?.response.data.errorCode === "B2B_LOGIN_400" || error?.response.data.errorCode === "B2B_LOGIN_500") {
                    //                 setErrorMessage(error.response.data.errorMessage);
                    //             }
                    //         })
                    //     }

                    // }).catch(error => {
                    //     if (error?.response.data.errorCode === "CLIENT_DETAILS_NOT_FOUND" || error?.response.data.errorCode === "B2B_GET_CLIENT_CREDENTIALS_404") {
                    //         setErrorMessage(error.response.data.errorMessage);
                    //     }
                    // })
                    setTimeout(() => {
                        setLoader(false)
                        navigate('/dashboard');
                    }, 2000);
                }
            }).catch(error => {
                setLoader(false)
                if (error?.response.data.errorCode === "B2B_USER_LOGIN_403") {
                    // setErrorMessage(error.response.data.errorMessage);
                    const param = {
                        email: email
                    }
                    resendEmail(param).then(resp => {
                        if (resp.statusCode === "SUCCESS") {
                            setErrorMessage(resp.statusMessage)
                        }
                    }).catch(error => {
                        // if (error?.response.data.errorCode === "B2B_RESEND_EMAIL_409") {
                        //     //setUserExists(error?.response.data.errorMessage)
                        //     setErrorMessage(error?.response.data.errorMessage)
                        // }
                    })

                } else if (error?.response.data.errorCode === "401 UNAUTHORIZED" || error?.response.data.errorCode === "B2B_USER_LOGIN_400"
                    || error?.response.data.errorCode === "B2B_USER_LOGIN_404") {
                    setErrorMessage(error.response.data.errorMessage);
                }
            })
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            login()
        }
    };

    return (
        <>
            {loader && <Loader />}
            <div style={{ marginBottom: 0 }}>
                <div className='container mt-3'>
                    <div className='row g-3 mb-3 align-items-center'>
                        <div className='col-5'>
                            <a className='navbar-brand logo-images-boxes' href='/'>
                                <img src={Logo} className='img-fluid' /></a>
                        </div>
                    </div>
                </div>
                <div className='container mt-2 mt-md-4'>
                    <div className='row main-box-border'>
                        <div className='col-sm-12 col-lg-5 px-0'>
                            <div className='stay-box'>
                                <div>
                                    <h1 className='color'>
                                        Start your Business Journey with Boost.Money!
                                    </h1>
                                    {/* <p>
                                        There is so much you can do to improve your score..
                                    </p> */}
                                </div>
                            </div>
                        </div>

                        <div className='col-sm-12 col-lg-7 mt-1 mt-sm-0 form-main-box'>
                            <div className='form-box px-sm-1 row'>
                                <div className='pt-3 col-md-8 offset-md-2'>
                                    <h1 className=' text-start mb-md-0'>
                                        Login To Your Account
                                    </h1>
                                    {/* <p className='fw-semibold mt-md-3'>
                                        Enter Mobile Number
                                    </p> */}
                                    <div className='form-input-box login-box'>
                                        <form>
                                            <div className='form-inner-box'>
                                                <div className=''>
                                                    <div className='label-label'>Email</div>
                                                </div>
                                                <div>
                                                    <input type="text" id='email' className='input-box form-control' value={email} onChange={(e) => { setEmail(e.target.value) }} onKeyDown={handleKeyDown} />
                                                    {(showError && email === '') && <span className="demo-tetx">Please enter your email</span>}
                                                    {emailVerify !== '' && <span className="demo-tetx mt-2">{emailVerify}</span>}
                                                </div>
                                            </div>

                                            <div className='form-inner-box'>
                                                <div className=''>
                                                    <div className='label-label'>Password </div>
                                                </div>
                                                <div>
                                                    <input type="password" id='passwrd' className='input-box form-control' value={passwrd} onChange={(e) => { setPassword(e.target.value) }} onKeyDown={handleKeyDown} />
                                                    {(showError && passwrd === '') && <span className="demo-tetx">Please enter your password</span>}
                                                    {/* {(showError && (passwrd.length !== 8 && passwrd !== '')) && <span className="demo-tetx">Please enter valid password</span>} */}
                                                </div>
                                            </div>
                                            {(errorMessage && errorMessage !== '') && <span className="demo-tetx">{errorMessage}</span>}
                                            <div className='forgot-pass mt-2'>
                                                <Link className='new-user-sign-up-text' to={'/email-password'}>
                                                    <span>Forgot Password ?</span>
                                                </Link>
                                            </div>

                                            <div className='form-inner-box form-button-box mt-1 mt-md-5'>
                                                <div className='row g-2 align-items-center'>
                                                    <div className='col-12 col-md-6 order-2 order-md-1'>
                                                        <div className='text-center text-sm-start my-2 my-sm-0'>
                                                            <span>
                                                                <span className='new-user-text'>New user? </span>
                                                                <Link className='new-user-sign-up-text' to={'/register'}>
                                                                    <span>Register Now</span>
                                                                </Link>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-6 order-1 order-md-2 text-end'>
                                                        <div className='text-end my-2 my-sm-0'>
                                                            <button type='button' className='next-button'
                                                                onClick={() => {
                                                                    login();
                                                                }}>Login</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-box'>
                <Footer />
            </div>
        </>
    );
}

export default Login;