import '../../../App.css'
import '../Component/middle-cd.css'

const INFO_IC = require("../../../images/assets/info.png");
const CHAT_IC = require("../../../images/assets/msg_ic.png");
const PRIVACY_IC = require("../../../images/assets/privacy.png");

const MiddleContentCD = (props) => {

    const handleClick = () => {
        props.onChildClick();
    };

    return (<>
        <div className="container bg-content-center mt-3 ms-lg-2 me-lg-2 ms-3 me-3">
            <p className="tips-txt pt-3">Tips for getting started</p>
            <div className='hr-white-line'></div>
            <div className="col-12 d-flex flex-wrap pt-4 ps-4 pe-4 pb-3 justify-content-between">
                <div className='col-lg-3 col-md-3 col-sm-12 pt-3'>
                    {/* <div className="img-txt">
                        <img src={CHAT_IC} alt="sub-icon" className="sub-icon" />
                        <p className="sub-title">Ask Boost AI</p>
                    </div> */}
                    {/* <p className="sub-desc pt-2 ps-1">BOOST AI can answer your questions related to your financial data like Bank transactions, Tax  and Credit Report.</p> */}
                    <div className="img-txt">
                        <img src={CHAT_IC} alt="sub-icon" className="sub-icon" />
                        <p className="sub-title">Add Your Bank Account(S)</p>
                    </div>
                    {/* <p className="sub-desc pt-2 ps-1">Add Your Bank Account(S)</p> */}
                </div>

                <div className='col-lg-4 col-md-4 col-sm-12 pt-3'>
                    {/* <div className="img-txt">
                        <img src={PRIVACY_IC} alt="sub-icon" className="sub-icon" />
                        <p className="sub-title">Sensitive data</p>
                    </div> */}
                    {/* <p className="sub-desc pt-2 ps-1">Your conversations are processed by human reviewers to improve Boost AI. Don’t enter anything you wouldn’t want reviewed or used.</p> */}
                    <div className="img-txt">
                        <img src={PRIVACY_IC} alt="sub-icon" className="sub-icon" />
                        <p className="sub-title">Get Insights And Recommendations</p>
                    </div>
                    {/* <p className="sub-desc pt-2 ps-1">Get Insights And Recommendations</p> */}
                </div>

                <div className='col-lg-4 col-md-3 col-sm-12 pt-3'>
                    {/* <div className="img-txt">
                        <img src={INFO_IC} alt="sub-icon" className="sub-icon" />
                        <p className="sub-title">Check your facts</p>
                    </div> */}
                    {/* <p className="sub-desc pt-2 ps-1">We have safeguards, but sometimes Boost AI can make mistakes.</p> */}
                    <div className="img-txt">
                        <img src={INFO_IC} alt="sub-icon" className="sub-icon" />
                        <p className="sub-title">Boost Your Credit Score And Finances</p>
                    </div>
                    {/* <p className="sub-desc pt-2 ps-1">Boost Your Credit Score And Finances</p> */}
                </div>

            </div>
            <div className="py-4 mt-5">
                <div onClick={handleClick} id="callApiOpenSdk" className="button-header">{props.BtnLabel}</div>
            </div>
        </div>
    </>);
}

export default MiddleContentCD;